import React, {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {listConfig} from "./Config";
import Button from "react-bootstrap/Button";
import {BiListMinus} from "react-icons/bi";
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import {
    getMousePos, navigation, pointIsInside, transformRectangle
} from "./Tools";
import {drawFigures, drawLists, loadingCanvas} from "./Drawing";
import {changeList} from "./StructTreeActions";
import {AiOutlineDelete} from "react-icons/ai";

import {
    addListItem, deleteListLine, getAllMCRefs, getBaseListUuids, getFlatList, getListRectangle,
    listAsHtml, processLists, updateList
} from "./utils/UtilsList";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import {resizingX, resizingY, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";
import StepNavigationButtons from "./StepNavigationButtons";

const Lists = ({pdf, pdfInfo, stepSelected, setStep, menuSize, setMenuSize, showPageMenu, setShowPageMenu, setStepSelected, setPdfInfo}) => {

    const lists = useRef([]);
    const listI = useRef(-1);
    const listPartI = useRef(0);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);

    // mouse position
    const startPos = useRef(null);
    const curPos = useRef(null);

    // canvas list
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const isDrawing = useRef(false);
    const selectedListElement = useRef(null);
    const lines = useRef([]);
    const [renderI, setRenderI] = useState(0);
    const highlightListItem = useRef(null);

    const queryClient = useQueryClient();

    const viewed = useRef([true]);
    const [showWarning, setShowWarning] = useState(false);

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            const checkedAll = viewed.current.every(t => t) || stepSelected < 5;
            updateStep(() => {
                if (checkedAll) setStep(stepSelected);
                else setShowWarning(true);
            });
        }
    }, [stepSelected]);

    // init canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        listsQuery.refetch();
        viewed.current[listI.current] = true;
        setPdfInfo(prevState => ({
            ...prevState,
            updateStep: updateStep,
        }));
    }, []);


    // get the lists
    const listsQuery = useQuery({
        queryKey: ["Lists", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "L", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            lists.current = data;
            if (lists.current.length > 0) {

                lists.current = processLists(lists.current, true);

                // set list variables
                pageNum.current = getListRectangle(lists.current[0], getBaseListUuids(lists.current[0])[0]).page;

                if (viewed.current.length === 1) {
                    viewed.current = Array.from({length: lists.current.length}, _ => false);
                }
                viewed.current[0] = true;
                listI.current = 0;
                setRenderI(prevState => prevState + 1);
            }
        }
    });

    // get the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null && pageNum.current > -1,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    // update tables
    const listMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    drawing();

    function setHighlightListItem(li) {
        highlightListItem.current = li;
        requestAnimationFrame(drawing);
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        return listMutation.mutate({pdfInfo: pdfInfo, modifications: lists.current.map(list => {
                let baseLists = [];
                let baseUuids = getBaseListUuids(list);
                for (let listItem of list) {
                    if (baseLists[baseUuids.indexOf(listItem.baseListUuid)] == null) {
                        baseLists[baseUuids.indexOf(listItem.baseListUuid)] = [listItem];
                    }
                    else {
                        baseLists[baseUuids.indexOf(listItem.baseListUuid)].push(listItem);
                    }
                }
                const mod =  baseLists.map(baseList => changeList({list: baseList}));
                return mod;
                }
            ).flat(1)}, {
            onSuccess: () => {
                setPdfInfo(prevState => ({
                    ...prevState,
                    saved: (new Date()).getTime(),
                }));
                queryClient.invalidateQueries(["Lists", pdfInfo.fileid]).then(() => {
                    if (onSuccessFunction != null) {
                        onSuccessFunction();
                    }
                });
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (!listsQuery.isLoading && lists.current.length !== 0 && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                lines.current = [];
                drawLists({
                    ctx: ctx.current,
                    list: getFlatList(lists.current[listI.current]),
                    uuid: getBaseListUuids(lists.current[listI.current])[listPartI.current],
                    image: pageImage.data,
                    selectedListElement: selectedListElement.current,
                    lines: lines.current,
                    startPos: startPos.current,
                    curPos: curPos.current,
                    highlightListItem: highlightListItem.current
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else if (listsQuery.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
        else if (imageReady && ctx.current != null && pageImage.data != null) {
            drawFigures({ctx:ctx.current, figure: null, image: pageImage.data});
        }
    }

    /**
     * select a list element or line
     * @param e event
     */
    function onMouseClick(e) {
        // do nothing during loading
        if (listsQuery.isLoading || pageImage.isLoading) return
        // get mouse position
        const mousePos = getMousePos({e: e, canvasRef: canvasRef.current});

        // selected list line
        selectedListElement.current = null;
        lines.current.forEach((line, lineI) => {
            if (mousePos.y - listConfig.clickOffset < line.y && line.y < mousePos.y + listConfig.clickOffset && !line.connectedLine) {
                selectedListElement.current = lineI;
            }
        });

        // check if inside list rectangle
        const listRect = transformRectangle({
            rect: getListRectangle(lists.current[listI.current], getBaseListUuids(lists.current[listI.current])[listPartI.current]),
            imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]
        });
        if (!pointIsInside(mousePos, listRect)) {
            selectedListElement.current = null;
        }
        // check if first and last element are possible to select
        const flatList = getFlatList(lists.current[listI.current]);
        if (selectedListElement.current === 0 && flatList[0].baseListUuid === getBaseListUuids(lists.current[listI.current])[listPartI.current]) {
            selectedListElement.current = null;
        }
        if (selectedListElement.current === lines.current.length - 1 && flatList[flatList.length - 1].baseListUuid === getBaseListUuids(lists.current[listI.current])[listPartI.current]) {
            selectedListElement.current = null;
        }

        // list element selected
        setRenderI(prevState => prevState + 1);
        drawing();
    }

    /**
     * start drawing
     * @param e event
     */
    function onMouseDown(e) {
        // do nothing during loading
        if (listsQuery.isLoading || pageImage.isLoading) return
        startPos.current = getMousePos({e: e, canvasRef: canvasRef.current})
        isDrawing.current = true;
        requestAnimationFrame(drawing);
    }

    /**
     * draw the rectangle
     * @param e event
     */
    function onMouseMove(e) {
        // do nothing during loading
        if (listsQuery.isLoading || pageImage.isLoading) return
        if (isDrawing.current) {
            curPos.current = getMousePos({e: e, canvasRef: canvasRef.current});
            requestAnimationFrame(drawing);
        }
    }

    /**
     * stop drawing create new struct element
     * @param e event
     */
    function onMouseUp(e) {
        // do nothing during loading
        if (listsQuery.isLoading || pageImage.isLoading) return
        if (isDrawing.current && startPos.current != null && curPos.current != null && Math.abs(startPos.current.x - curPos.current.x) > listConfig.clickOffset) {
            addListItem({
                list: lists.current[listI.current],
                startPos: startPos.current,
                curPos: curPos.current,
                imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight],
                baseListUuid: getBaseListUuids(lists.current[listI.current])[listPartI.current],
            });
        }
        startPos.current = null;
        curPos.current = null;
        isDrawing.current = false;
        setRenderI(prevState => prevState + 1);
        requestAnimationFrame(drawing);
    }

    function resetList() {
        const elements = getAllMCRefs({element: lists.current[listI.current]});
        lists.current[listI.current] = {type: "L", uuid: lists.current[listI.current].uuid, children: [{type: "LI", children: [{type:"LBody", children: elements}]}]};
        updateList({list: lists.current[listI.current], positions: [0]});
        drawing();
        selectedListElement.current = null;
        setRenderI(prevState => prevState + 1);
        setRenderI(prevState => prevState + 1);
    }

    return (
        <div className="workspaceArea"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked all lists?"}
                bodyMessage={<>It seems that you have not checked the following lists: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br /> Do you want to check them before you continue?</>}
                currentTask={5}
                setTask={setStep}
                taskSelected={stepSelected}/>
            <div id="menu" className="menu" style={{width: menuSize + "px"}}>
                <div className="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 5: Define List Structure"
                    step={5}
                />
                {navigation({
                    callback: (i) => {
                        if (i !== listI.current) {
                            listPartI.current = 0;
                            listI.current = i;
                            setRenderI(prevState => prevState + 1);
                            pageNum.current = getListRectangle(lists.current[i], getBaseListUuids(lists.current[i])[0]).page;
                            selectedListElement.current = null;
                        }
                    },
                    length: lists.current.length,
                    i: listI.current,
                    title: "Lists",
                    viewed: viewed.current
                })}
                <div className="buttonRow">
                    <Button variant="light" onClick={() => {
                        deleteListLine({
                            list: lists.current[listI.current],
                            selectedLine: selectedListElement.current,
                            baseListUuid: getBaseListUuids(lists.current[listI.current])[listPartI.current]
                        });
                        selectedListElement.current = null;
                        setRenderI(prevState => prevState + 1);
                    }} disabled={!(typeof selectedListElement.current === 'number')} className="actionButton">
                        <BiListMinus size="1.5em"/><br/> Combine List Items
                    </Button>
                    <Button variant="light" onClick={resetList} className="actionButton">
                        <AiOutlineDelete size="1.5em"/> <br/>
                        Delete All List Items
                    </Button>
                </div>
                <div className="blueBox">
                    <h3>{lists.current != null && lists.current.length > 0 ? <>List Structure</> : "No list found in the document"}</h3>
                    <ul className="listAsHtml">
                        {lists.current[listI.current] != null && getBaseListUuids(lists.current[listI.current]).length > 1 ? navigation({
                            callback: (i) => {
                                if (i !== listPartI.current) {
                                    listPartI.current = i;
                                    setRenderI(prevState => prevState + 1);
                                    pageNum.current = getListRectangle(lists.current[listI.current], getBaseListUuids(lists.current[listI.current])[i]).page;
                                    selectedListElement.current = null;
                                }
                            },
                            length: getBaseListUuids(lists.current[listI.current]).length,
                            i: listPartI.current,
                            title: "Parts",
                            viewed: [],
                            parts: true
                        }) : null}
                        {listAsHtml({
                            list: lists.current[listI.current],
                            setRender: setRenderI,
                            setHighlightListItem: setHighlightListItem
                        })}
                    </ul>
                </div>
                <StepNavigationButtons nextStep={() => setStepSelected(6)} previousStep={() => setStepSelected(4)}/>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                onMouseClick={onMouseClick}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={listsQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default Lists;
