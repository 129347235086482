import React, {useState, useRef, useEffect } from 'react';
import {updatePageStructTree, predictRegions, getPageImagePdfJs} from "./APIcalls";
import {
    getNotMarked,
    getStructElemByPoint,
    changeTagType,
    transformRectangle,
    getChangeBoxPoint,
    getMousePos, navigation
} from "./Tools";
import {drawRegions, loadingCanvas} from "./Drawing";
import {readingOrderConfig, regionConfig, tagTypes, colors} from "./Config"

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { AiOutlineDelete} from 'react-icons/ai';
import {useMutation, useQuery} from "@tanstack/react-query";
import {deleteTag, newTag, updateConnectedElements, updateTagChildren, updateTagType} from "./StructTreeActions";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import {MdOutlineAutoFixHigh} from "react-icons/md";
import {resizingX, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";
import {LuCopyMinus, LuCopyPlus} from "react-icons/lu";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import StepNavigationButtons from "./StepNavigationButtons";

const Regions = ({pdf, pdfInfo, stepSelected, setStep, menuSize, setMenuSize, showPageMenu, setShowPageMenu, setStepSelected, setPdfInfo}) => {

    // page related states
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const viewed = useRef(Array.from({length: pdfInfo.numberOfPages}, _ => false));

    // struct tree related states
    const structTree = useRef({structTree: null, operators: null});
    const structTreeHistory = useRef([]);
    const selectedStructElem = useRef([]);
    const notMarked = useRef(null);
    const modifications = useRef([]);

    // canvas related states
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const isDrawing = useRef(false);
    const newStructElemOperators = useRef(false);
    const startPos = useRef(null);
    const curPos = useRef(null);
    const changeBoxSize = useRef(false);

    // helper states for visualization settings
    const [showLabels, setShowLabels] = useState(true);
    const [showArtifacts, setShowArtifacts] = useState(false);
    const [multiSelect, setMultiSelect] = useState(false);
    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);

    // other states
    const [showWarning, setShowWarning] = useState(false);
    const [render, setRender] = useState(0);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            updateStep(() => {
                if (viewed.current.every(t => t) || stepSelected < 1) setStep(stepSelected);
                else setShowWarning(true);
            });
        }
    }, [stepSelected]);

    // initialize canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        updateStep();
        viewed.current[pageNum.current - 1] = true;
        // add keyboard listener
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
        setPdfInfo(prevState => ({
            ...prevState,
            updateStep: updateStep,
        }));
    }, []);

    useEffect(() => {
        structTreeHistory.current.push(structTree.current.structTree);
    }, [structTree.current.structTree]);

    // getting the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: () => drawing()
    });

    // update and get structTree
    const structTreeMutation = useMutation({
        mutationFn: ({pdfInfo, oldPage, newPage, modifications, pdf}) => updatePageStructTree({pdfInfo: pdfInfo, oldPage: oldPage, newPage: newPage, modifications: modifications, addArtifacts: true, pdf: pdf}),
        onSuccess: data => {
            structTree.current = data;
            notMarked.current = getNotMarked(structTree.current);
            // replace selected elements if in struct tree
            selectedStructElem.current = selectedStructElem.current.map(elem => {
                let elemI = structTree.current.structTree.findIndex(e => e.uuid === elem.uuid);
                if (elemI > -1) {
                    return structTree.current.structTree[elemI];
                }
                return elem;
            });
            drawing();
        }
    })

    // predict regions
    const predictRegionsMutation = useMutation({
        mutationFn: ({pdfInfo, pageNum}) => predictRegions({pdfInfo: pdfInfo, page: pageNum}),
        onSuccess: data => {
            modifications.current = [];
            updateStep();
        }
    })

    /**
     * select a struct element
     * @param e event
     */
    function onMouseClick(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading) return

        // get mouse position and selected element
        const mousePos = getMousePos({e: e, canvasRef: canvasRef.current});
        const tempSelection = getStructElemByPoint(structTree.current.structTree, mousePos, [pageImage.data.naturalWidth, pageImage.data.naturalHeight], showArtifacts);


        if (multiSelect) {
            if (tempSelection != null) {
                // add new element
                if (selectedStructElem.current.every(elem => elem.uuid !== tempSelection.uuid)) {
                    if (tempSelection.connectedElements.length > 1) {
                        selectedStructElem.current = selectedStructElem.current.concat(tempSelection.connectedElements);
                    }
                    else {
                        selectedStructElem.current.push(tempSelection);
                    }
                }
                // remove selected element
                else {
                    selectedStructElem.current = selectedStructElem.current.filter(elem => elem.uuid !== tempSelection.uuid);
                    if (selectedStructElem.current.length === 0) selectedStructElem.current = [];
                }
            }
        }
        else {
            if (tempSelection != null) {
                if (tempSelection.connectedElements.length > 1) {
                    selectedStructElem.current = tempSelection.connectedElements;
                }
                else {
                    selectedStructElem.current = [tempSelection];
                }
            }
            else selectedStructElem.current = [];
        }
        drawing();
        setRender(prevState => prevState + 1);
    }

    /**
     * start drawing
     * @param e event
     */
    function onMouseDown(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading || multiSelect) return
        newStructElemOperators.current = [];
        curPos.current = startPos.current = getMousePos({e: e, canvasRef: canvasRef.current});

        if (selectedStructElem.current.length > 0) {
            changeBoxSize.current = false;
            selectedStructElem.current.forEach(elem => {
                const rect = transformRectangle({rect: elem.rectangle, imageSize: [pageImage.data.naturalWidth, pageImage.data.naturalHeight]});
                const point = getChangeBoxPoint({curX: curPos.current.x, curY: curPos.current.y, rect: rect, threshold: regionConfig.clickOffset});
                if (point) {
                    changeBoxSize.current = {uuid: elem.uuid, point: point};
                }
            });
            if (!changeBoxSize.current) {
                curPos.current = startPos.current = null;
            }
            else {
                requestAnimationFrame(drawing);
            }
        }
        else {
            selectedStructElem.current = [];
            setRender(prevState => prevState + 1);
            isDrawing.current = true;
            requestAnimationFrame(drawing)
        }
    }

    /**
     * draw the rectangle
     * @param e event
     */
    function onMouseMove(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading || (!isDrawing.current && !changeBoxSize.current) || multiSelect) return
        curPos.current = getMousePos({e: e, canvasRef: canvasRef.current})
        requestAnimationFrame(drawing);
    }

    /**
     * stop drawing create new struct element
     * @param e event
     */
    function onMouseUp(e) {
        // do nothing during loading
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading || (!isDrawing.current && !changeBoxSize.current) || multiSelect) return

        // if changing box size
        if (changeBoxSize.current) {
            // get all operators of new element
            newStructElemOperators.current = [...new Set(newStructElemOperators.current.sort((o1, o2) => (o1.id > o2.id) ? 1 : (o1.id < o2.id) ? -1 : 0))];
            // check if operators changed
            const elemI = structTree.current.structTree.findIndex(elem => elem.uuid === changeBoxSize.current.uuid);
            if (structTree.current.structTree[elemI].children.map(o => {return o.uuid;}).toString() !== newStructElemOperators.current.map(o => {return o.uuid;}).toString() && newStructElemOperators.current.length > 0) {
                updateTagChildren({
                    elem: structTree.current.structTree[elemI],
                    newChildren:newStructElemOperators.current,
                    modifications: modifications.current,
                    structTree: structTree.current.structTree
                });
            }
            changeBoxSize.current = false;
        }
        else {
            // if no operators selected
            if (newStructElemOperators.current.length === 0) {
                curPos.current = startPos.current = null;
                isDrawing.current = false;
                return;
            }
            // stop drawing
            isDrawing.current = false;
            // sort struct element operators by id
            newStructElemOperators.current.sort((o1, o2) => (o1.id > o2.id) ? 1 : (o1.id < o2.id) ? -1 : 0);
            // get new struct element
            const tempStructElem = {id: null, children: newStructElemOperators.current.map(o => {
                    return {children: [], uuid: o.uuid, id: o.id, rectangle: o.rectangle, text: o.text, type: "MCRef"};
                }), rectangle: {llx: 99999, lly: 99999, urx: -1, ury: -1, page: pageNum.current}, text: "", type: "P"};
            // update text and bounding box of new element
            newStructElemOperators.current.forEach(o => {
                tempStructElem.text += o.text;
                if (tempStructElem.rectangle.llx > o.rectangle.llx) tempStructElem.rectangle.llx = o.rectangle.llx;
                if (tempStructElem.rectangle.urx < o.rectangle.urx) tempStructElem.rectangle.urx = o.rectangle.urx;
                if (tempStructElem.rectangle.lly > o.rectangle.lly) tempStructElem.rectangle.lly = o.rectangle.lly;
                if (tempStructElem.rectangle.ury < o.rectangle.ury) tempStructElem.rectangle.ury = o.rectangle.ury;
            })
            // add new tag
            newTag({elem: tempStructElem, modifications: modifications.current, structTree: structTree.current.structTree});
            // set the new tag as selected
            selectedStructElem.current = [tempStructElem];

        }
        // reset values
        newStructElemOperators.current = [];
        curPos.current = startPos.current = null;
        notMarked.current = getNotMarked(structTree.current);
        drawing();
        setRender(prevState => prevState + 1);
    }

    function onKeyDown(e) {
        if (e.key === "Meta" || e.key === 'Control') {
            setMultiSelect(true);
        }
    }

    function onKeyUp(e) {
        if (e.key === "Meta" || e.key === 'Control') {
            setMultiSelect(false);
        }
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction, newPage) {
        if (newPage == null) newPage = pageNum.current;
        return structTreeMutation.mutate({pdfInfo: pdfInfo, oldPage: pageNum.current, newPage: newPage, modifications: modifications.current, pdf: pdf}, {onSuccess: () => {
                setPdfInfo(prevState => ({
                    ...prevState,
                    saved: (new Date()).getTime(),
                }));
                modifications.current = [];
                if (onSuccessFunction != null) {
                    onSuccessFunction();
                }
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (!structTreeMutation.isLoading && notMarked.current != null && imageReady && ctx.current != null && pageImage.data != null) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawRegions({
                    ctx: ctx.current,
                    image: pageImage.data,
                    notMarked: notMarked,
                    structTree: structTree.current.structTree,
                    newStructElemOperators: newStructElemOperators,
                    selectedStructElem: selectedStructElem.current,
                    startPos: startPos.current,
                    curPos: curPos.current,
                    showRegionLabels: showLabels,
                    changeBoxSize: changeBoxSize.current,
                    showArtifacts: showArtifacts,
                });
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        if (structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading) {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
    }

    // draw image and rectangles
    drawing();

    function changePage(i) {
        if (i !== pageNum.current - 1) {
            setRender(prevState => prevState + 1);
            updateStep(() => {}, i + 1);
            pageNum.current = i + 1;
            modifications.current = [];
        }
    }

    function showSelectedElement() {
        if (selectedStructElem.current.length === 0) {
            return <div id="selectedRegion" className="blueBox">
                <h3>No Region Selected</h3>
                <p>To edit a region, select a region on the page view.</p>
            </div>
        }
        let type = "    ";
        if (selectedStructElem.current.every(elem => elem.type === selectedStructElem.current[0].type)) {
            type = tagTypes[selectedStructElem.current[0].type];
            if (type == null) {
                type = selectedStructElem.current[0].type;
            }
        }
        return <div className="blueBox">
            <h3>{selectedStructElem.current.length > 1 ? selectedStructElem.current.length + " Regions Selected" : "1 Region Selected"}</h3>
            <div className="d-flex flex-row align-items-center">
                <h4 className="tagTypeTitle">Region Type</h4>
                <DropdownButton className="tagTypeDropDown"
                                title={type}
                                variant="light" key="tagTypeDropdown">
                    {[...new Set(Object.values(tagTypes))].map((t, i) => {
                        return <Dropdown.Item active={type === t}
                                              onClick={() => {
                                                  if (selectedStructElem.current.length > 1 || tagTypes[selectedStructElem.current[0].type] !== t) {
                                                      selectedStructElem.current.forEach(elem => {
                                                          changeTagType({
                                                              structTree: structTree.current.structTree,
                                                              selectedStructElem: elem,
                                                              modifications: modifications.current,
                                                              newType: Object.keys(tagTypes)[Object.values(tagTypes).findIndex((e) => e === t)]
                                                          });
                                                      });
                                                      setRender(prevState => prevState + 1);
                                                  }
                                              }} key={i}>
                            {t}
                        </Dropdown.Item>
                    })}
                </DropdownButton>
                {type === "Artifact" ?
                    <p id="artifactHint">will be ignored by the screen reader</p> : null}
            </div>
            <div className="d-flex flex-row">
                <h4>Content</h4>
            </div>
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div
                            className="regionItemList"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {
                                selectedStructElem.current.map((element, index) => (
                                    <Draggable key={String(element.id)} draggableId={String(element.id)}
                                               index={index}>
                                        {(provided) => (
                                            <div
                                                className="item-container"
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <div style={element.artifact ? {
                                                    borderColor: colors["Artifact"],
                                                    backgroundColor: readingOrderConfig.artifactColor
                                                } : {borderColor: colors[element.type]}}
                                                     className="regionItem">
                                                    <b className="itemType">{Object.keys(tagTypes).includes(element.type) ? tagTypes[element.type] : element.type} <br/> (Page {element.rectangle.page})</b>
                                                    <div className="itemText">
                                                        {element.text}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="buttonRow">
                <Button variant="light" onClick={() => {
                    selectedStructElem.current.forEach(elem => {
                        deleteTag({
                            structTree: structTree.current.structTree,
                            elem: elem,
                            modifications: modifications.current
                        });
                    })
                    notMarked.current = getNotMarked({
                        operators: structTree.current.operators,
                        structTree: structTree.current.structTree
                    });
                    selectedStructElem.current = [];
                    setRender(prevState => prevState + 1);
                    setMultiSelect(false);
                    drawing();
                }} disabled={selectedStructElem.current.length === 0} className="blueBoxButton">
                    <AiOutlineDelete/> <br/>
                    {selectedStructElem.current.length > 1 ? "Delete All Selected Regions" : "Delete Region"}
                </Button>
                <Button variant="light" onClick={() => combineElements(selectedStructElem.current)}
                        disabled={selectedStructElem.current.length < 2} className="blueBoxButton">
                    <LuCopyPlus/> <br/>
                    Combine Regions
                </Button>
                <Button variant="light" onClick={() => separateElements(selectedStructElem.current)}
                        disabled={selectedStructElem.current.length < 2} className="blueBoxButton">
                    <LuCopyMinus/> <br/>
                    Separate Regions
                </Button>
            </div>
        </div>
    }

    function combineElements(elements) {
        // update tag type if required
        const type = elements[0].type;
        elements.forEach(element => {
            if (element.type !== type) {
                element.type = type;
                modifications.current.push(updateTagType({uuid: element.uuid, newType: type}));
            }
            let elemI = structTree.current.structTree.findIndex(elem => elem.uuid === element.uuid);
            if (elemI > -1) {
                structTree.current.structTree[elemI].connectedElements = elements;
            }
        });
        // change connected elements
        modifications.current.push(updateConnectedElements({connectedElements: elements}));
        selectedStructElem.current = [];
        drawing();
        setRender(prevState => prevState + 1);
    }

    function separateElements(elements) {
        // change connected elements
        elements.forEach(element => {
            modifications.current.push(updateConnectedElements({connectedElements: [element]}));
            let elemI = structTree.current.structTree.findIndex(elem => elem.uuid === element.uuid);
            if (elemI > -1) {
                structTree.current.structTree[elemI].connectedElements = [];
            }
        })
        selectedStructElem.current = [];
        drawing();
        setRender(prevState => prevState + 1);
    }

    // Function to update list on drop
    function handleDrop(droppedItem) {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        // change order
        selectedStructElem.current.splice(droppedItem.destination.index, 0, selectedStructElem.current.splice(droppedItem.source.index, 1)[0]);
        drawing();
        setRender(prevState => prevState + 1);
    }

    return (
        <div className="workspaceArea"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning} setShowWarning={setShowWarning}
                titleMessage={"Have you checked the regions on all pages?"}
                bodyMessage={<>It seems that you have not checked the regions on the following
                    pages: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br/> Do
                    you want to check them before you continue?</>}
                currentTask={1}
                setTask={setStep}
                taskSelected={stepSelected}/>
            <div id="menu" className="menu" style={{width: menuSize + "px"}}>
                <div className="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 1: Define Regions"
                    step={1}
                />
                {navigation({
                    callback: changePage,
                    length: pdfInfo.numberOfPages,
                    i: pageNum.current - 1,
                    title: "Pages",
                    viewed: viewed.current
                })}
                <div className="buttonRow">
                    <Button variant="light" onClick={() => {
                        predictRegionsMutation.mutate({pdfInfo: pdfInfo, pageNum: pageNum.current});
                    }} className="actionButton">
                        <MdOutlineAutoFixHigh size="1.5em"/><br/>
                        Detect Regions
                    </Button>
                    <Button variant="light" onClick={() => {
                        const uuids = structTree.current.structTree.map(e => e.uuid);
                        uuids.forEach((uuid) => {
                            deleteTag({
                                structTree: structTree.current.structTree,
                                elem: {uuid: uuid},
                                modifications: modifications.current
                            });
                        });
                        notMarked.current = getNotMarked({
                            operators: structTree.current.operators,
                            structTree: structTree.current.structTree
                        });
                        selectedStructElem.current = [];
                        drawing();
                        setRender(prevState => prevState + 1);
                    }} className="actionButton">
                        <AiOutlineDelete size="1.5em"/><br/>
                        Delete All
                    </Button>
                </div>
                {showSelectedElement()}
                <StepNavigationButtons nextStep={() => setStepSelected(2)} previousStep={() => setStepSelected(0)}/>
            </div>
            <PageView
                pageNum={pageNum.current}
                extraTitleText={notMarked.current != null ? " (" + notMarked.current.length + " elements not tagged)" : null}
                canvasRef={canvasRef}
                onMouseClick={onMouseClick}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                showLabels={showLabels}
                setShowLabels={setShowLabels}
                showArtifacts={showArtifacts}
                setShowArtifacts={setShowArtifacts}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                multiSelect={multiSelect}
                setMultiSelect={setMultiSelect}
                isLoading={structTreeMutation.isLoading || predictRegionsMutation.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    )

}
export default Regions;
