import React from "react";
import {Link} from "react-router-dom";

const Step3 = () => {
    return <div className="instructionsText">
        <Link to="/help#whyIsHeadingStructureCrucial" target={"_blank"}>More information about heading structure</Link>.
        <ul>
            <li><strong>Most heading levels wrong:</strong> Select <em>Detect Heading Levels</em> to
                automatically detect the heading levels and refine them.</li>
            <li><strong>Few heading levels wrong:</strong> Change the heading levels in the list below by using the
                dropdown menu. Only valid options are visible.</li>
        </ul>
    </div>
}

export default Step3;
