import React, {useState, useRef} from "react";

import Header from "./Header"
import NavBar from "./NavBar"
import UploadPDF from "./UploadPDF";
import Regions from "./Regions";
import Headings from "./Headings"
import ReadingOrder from "./ReadingOrder";
import Tables from "./Tables";
import Lists from "./Lists";
import Figures from "./Figures";
import Formulae from "./Formulae";
import MetaInformation from "./MetaInformation";
import { Routes, Route } from "react-router-dom";
import InfoLayout from "./InfoLayout";
import WelcomePage from "./WelcomePage";
import {contactContent, helpContent, legalContent} from "./InfoContent";

function getTasks(language) {
    return ["UPLOAD PDF", "REGIONS", "READING ORDER", "HEADERS", "TABLES", "LISTS", "FIGURES",
        "MATHEMATICAL FORMULAE", "META INFORMATION"]
}

const App = () => {
    const [language, setLanguage] = useState("EN");
    const [pdfInfo, setPdfInfo] = useState({filename: null, fileid: null, numberOfPages: 0});
    const pdf = useRef(null);
    const saved = useRef(null);
    const [steps, setSteps] = useState(getTasks(language));
    const [step, setStep] = useState(0);
    const [renderI, setRenderI] = useState(0);
    const stepSelected = useRef(-1);

    // resize the elements
    const [menuSize, setMenuSize] = useState(500);
    const [showPageMenu, setShowPageMenu] = useState(true);

    function setStep2(t) {
        stepSelected.current = -1;
        setStep(t);
        setRenderI(prevState => prevState + 1);
    }

    function setStepSelected(t) {
        if (t !== step) {
            stepSelected.current = t;
            setRenderI(prevState => prevState + 1);
        }
    }

    function changeLanguage(lang_code) {
        alert('Other languages are not supported yet');
    }

    return (
        <div className="body">
            <Header language={language} changeLanguage={changeLanguage} pdfInfo={pdfInfo} pdf={pdf} setStep={setStep2}/>
            <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/app" element={
                    <div className="navAndWorkspace">
                        <div className="nav-area">
                            <NavBar task_i={step} tasks={steps} setTask={setStepSelected}/>
                        </div>
                        <div className="workspace">
                            {
                                {
                                    0:
                                        <UploadPDF
                                            pdf={pdf}
                                            setPdfInfo={setPdfInfo}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            setStep={setStep}
                                        />,
                                    1:
                                        <Regions
                                            pdf={pdf.current}
                                            pdfInfo={pdfInfo}
                                            setPdfInfo={setPdfInfo}
                                            stepSelected={stepSelected.current}
                                            setStep={setStep2}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            showPageMenu={showPageMenu}
                                            setShowPageMenu={setShowPageMenu}
                                            setStepSelected={setStepSelected}
                                        />,
                                    2:
                                        <ReadingOrder
                                            pdf={pdf.current}
                                            pdfInfo={pdfInfo}
                                            setPdfInfo={setPdfInfo}
                                            stepSelected={stepSelected.current}
                                            setStep={setStep2}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            showPageMenu={showPageMenu}
                                            setShowPageMenu={setShowPageMenu}
                                            setStepSelected={setStepSelected}
                                        />,
                                    3:
                                        <Headings
                                            pdf={pdf.current}
                                            pdfInfo={pdfInfo}
                                            setPdfInfo={setPdfInfo}
                                            stepSelected={stepSelected.current}
                                            setStep={setStep2}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            showPageMenu={showPageMenu}
                                            setShowPageMenu={setShowPageMenu}
                                            setStepSelected={setStepSelected}
                                        />,
                                    4:
                                        <Tables
                                            pdf={pdf.current}
                                            pdfInfo={pdfInfo}
                                            setPdfInfo={setPdfInfo}
                                            stepSelected={stepSelected.current}
                                            setStep={setStep2}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            showPageMenu={showPageMenu}
                                            setShowPageMenu={setShowPageMenu}
                                            setStepSelected={setStepSelected}
                                        />,
                                    5:
                                        <Lists
                                            pdf={pdf.current}
                                            pdfInfo={pdfInfo}
                                            setPdfInfo={setPdfInfo}
                                            stepSelected={stepSelected.current}
                                            setStep={setStep2}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            showPageMenu={showPageMenu}
                                            setShowPageMenu={setShowPageMenu}
                                            setStepSelected={setStepSelected}
                                        />,
                                    6:
                                        <Figures
                                            pdf={pdf.current}
                                            pdfInfo={pdfInfo}
                                            setPdfInfo={setPdfInfo}
                                            stepSelected={stepSelected.current}
                                            setStep={setStep2}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            showPageMenu={showPageMenu}
                                            setShowPageMenu={setShowPageMenu}
                                            setStepSelected={setStepSelected}
                                        />,
                                    7:
                                        <Formulae
                                            pdf={pdf.current}
                                            pdfInfo={pdfInfo}
                                            setPdfInfo={setPdfInfo}
                                            stepSelected={stepSelected.current}
                                            setStep={setStep2}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            showPageMenu={showPageMenu}
                                            setShowPageMenu={setShowPageMenu}
                                            setStepSelected={setStepSelected}
                                        />,
                                    8:
                                         <MetaInformation
                                            pdf={pdf}
                                            pdfInfo={pdfInfo}
                                            setPdfInfo={setPdfInfo}
                                            stepSelected={stepSelected.current}
                                            setStep={setStep2}
                                            menuSize={menuSize}
                                            setMenuSize={setMenuSize}
                                            showPageMenu={showPageMenu}
                                            setShowPageMenu={setShowPageMenu}
                                            setStepSelected={setStepSelected}
                                        />
                                }[step]
                            }
                        </div>
                    </div>
                } />
                <Route path="/help" element={<InfoLayout content={helpContent()}/>}/>
                <Route path="/legal" element={<InfoLayout content={legalContent()} />}/>
                <Route path="/contact" element={<InfoLayout content={contactContent()} />}/>
            </Routes>
        </div>
    );
};

export default App;
