import React, {useEffect, useRef, useState} from 'react';
import {getPageImagePdfJs, getTagType, updateTags} from "./APIcalls";
import {useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import Form from 'react-bootstrap/Form';
import {
    changeElementI,
    changeElementPartI,
    changeTagType, getElement,
    imageCropper,
    navigation,
    processConnectedElements
} from "./Tools";
import {updateAltText} from "./StructTreeActions";
import {imageScaling} from "./Config";
import {drawFigures, loadingCanvas} from "./Drawing";
import Instructions from "./utils/Instructions";
import {errorDrawing, WarningNotComplete} from "./utils/ErrorMessages";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {PopoverBody, PopoverHeader} from "react-bootstrap";
import {resizingX, startResizeX} from "./utils/UtilsResize";
import PageView from "./PageView";
import StepNavigationButtons from "./StepNavigationButtons";

const Figures = ({pdf, pdfInfo, stepSelected, setStep, menuSize, setMenuSize, showPageMenu, setShowPageMenu, setStepSelected, setPdfInfo}) => {

    const figures = useRef([]);
    const figureI = useRef(-1);
    const figurePartI = useRef(0);
    const pageNum = useRef(1);
    const [imageReady, setImageReady] = useState(true);
    const modifications = useRef([]);
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const [renderI, setRenderI] = useState(0);

    const queryClient = useQueryClient();

    const viewed = useRef([true]);
    const [wordCount, setWordCount] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const [isArtifact, setIsArtifact] = useState(false);

    const [zoomFactor, setZoomFactor] = useState(1);

    // resize start values
    const startResizeValueMenu = useRef(null);

    // changing step
    useEffect(() => {
        if (stepSelected !== -1) {
            const checkedAll = viewed.current.every(t => t || stepSelected < 6);
            updateStep(() => {
                queryClient.invalidateQueries(["Figures", pdfInfo.fileid]).then(() => {
                    if (checkedAll) setStep(stepSelected);
                    else setShowWarning(true);
                });
            });
        }
    }, [stepSelected]);

    // init canvas
    useEffect(() => {
        if (canvasRef.current) {
            ctx.current = canvasRef.current.getContext('2d');
        }
        figuresQuery.refetch();
        viewed.current[figureI.current] = true;
        setPdfInfo(prevState => ({
            ...prevState,
            updateStep: updateStep,
        }));
    }, []);

    // get the figures
    const figuresQuery = useQuery({
        queryKey: ["Figures", pdfInfo.fileid],
        queryFn: () => getTagType({pdfInfo: pdfInfo, tagType: "Figure", pdf: pdf}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null,
        placeholderData: null,  // placeholder image
        onSuccess: data => {
            figures.current = data;
            if (figures.current.length !== 0) {

                // process connected elements
                figures.current = processConnectedElements(figures.current);

                pageNum.current = figures.current[0].rectangle.page;
                if (viewed.current.length === 1) {
                    viewed.current = Array.from({length: figures.current.length}, _ => false);
                }
                viewed.current[0] = true;
                setWordCount(figures.current[0].altText.split(" ").filter(w => w !== "").length);
                figureI.current = 0;
                setRenderI(prevState => prevState + 1);
            }
        }
    });

    // get the image
    const pageImage = useQuery({
        queryKey: ["image", pdfInfo.fileid, pageNum.current],
        queryFn: () => getPageImagePdfJs({pdf: pdf, pageNum: pageNum.current, setImageReady: setImageReady}),
        staleTime: 1000 * 60 * 5, // less fetching,
        enabled: pdfInfo.fileid != null && pageNum.current > -1,
        placeholderData: null,  // placeholder image
    });

    // update tables
    const figuresMutation = useMutation({
        mutationFn: ({pdfInfo, modifications}) => updateTags({pdfInfo: pdfInfo, modifications: modifications})
    })

    function changeFigureCallback(i) {
        updateStep();
        setWordCount(figures.current[i].altText.split(" ").filter(w => w !== "").length);
        if (figures.current[i].isArtifact) setIsArtifact(true);
        else setIsArtifact(false);
    }

    /**
     * Helper function for updating the step
     */
    function updateStep(onSuccessFunction) {
        if (document.getElementById('figureAltText-' + figureI.current) == null) {
            if (onSuccessFunction) {
                onSuccessFunction();
            }
            return;
        }
        const altText = document.getElementById('figureAltText-' + figureI.current).value;
        if (altText !== figures.current[figureI.current].altText) {
            if (!figures.current[figureI.current].isArtifact) modifications.current.push(updateAltText({
                uuid: figures.current[figureI.current].uuid,
                altText: altText
            }));
            figures.current[figureI.current].altText = altText;
        }
        return figuresMutation.mutate({pdfInfo: pdfInfo, modifications: modifications.current}, {
            onSuccess: () => {
                setPdfInfo(prevState => ({
                    ...prevState,
                    saved: (new Date()).getTime(),
                }));
                if (onSuccessFunction) {
                    return onSuccessFunction();
                }
            }
        });
    }


    /**
     * helper function for drawing
     */
    function drawing() {
        if (imageReady  && ctx.current != null && pageImage.data != null && !figuresQuery.isLoading) {
            try {
                canvasRef.current.height = pageImage.data.naturalHeight;
                canvasRef.current.width = pageImage.data.naturalWidth;
                drawFigures({ctx: ctx.current, figure: getElement(figures.current, figureI.current, figurePartI.current), image: pageImage.data});
            }
            catch (e) {
                errorDrawing({errorMessage: e});
            }
        }
        else {
            loadingCanvas({ctx: ctx.current, canvasRef: canvasRef.current});
        }
    }

    drawing();

    function showFigure() {
        drawing();
        let figure = getElement(figures.current, figureI.current, figurePartI.current);
        // if there are figures
        if (imageReady  && ctx.current != null && pageImage.data != null && !figuresQuery.isLoading) {
            return <div className="blueBox">
                {figures.current.length > 0 ?
                    <>
                        <h3>Figure Alternative Text</h3>
                        <div className="figureImgDiv">
                            <img src={imageCropper({
                                image: pageImage.data,
                                border: 5,
                                rectangle: {
                                    x: figure.rectangle.llx * imageScaling,
                                    y: figure.rectangle.lly * imageScaling,
                                    w: figure.rectangle.urx * imageScaling - figure.rectangle.llx * imageScaling,
                                    h: figure.rectangle.ury * imageScaling - figure.rectangle.lly * imageScaling
                                },
                                resize: 1})}/>
                        </div>
                            {figure != null && figures.current[figureI.current].connectedElements.length > 1 ? navigation({
                                callback: (i) => pageNum.current = changeElementPartI({
                                    i: i,
                                    elementI: figureI.current,
                                    elementPartI: figurePartI.current,
                                    pageNum: pageNum.current,
                                    elements: figures.current,
                                    setElementPartI: (i) => {
                                        figurePartI.current = i;
                                        setRenderI(prevState => prevState + 1);
                                    },
                                }),
                                length: figures.current[figureI.current].connectedElements.length,
                                i: figurePartI.current,
                                title: "Parts",
                                viewed: [],
                                parts: true
                            }) : null}

                        <Form className="alternativeTextInput">
                            <Form.Group key={'figureAltText-' + figureI.current} controlId={'figureAltText-' + figureI.current}>
                                <Form.Label className="altTextTitle"><h4 className="d-flex">Alternative Text (<p
                                    id={(50 - wordCount) > 10 ? "textLengthOk" : (50 - wordCount) > 0 ? "textLengthWarning" : "textLengthError"}>{(50 - wordCount)}</p> words
                                    left)</h4></Form.Label>
                                <Form.Control key={'figureAltText-' + figureI.current} type="alternative text" as="textarea" rows={5}
                                              defaultValue={figures.current[figureI.current].altText}
                                              onChange={(e) => setWordCount(e.target.value.split(" ").filter(w => w !== "").length)}
                                              disabled={isArtifact}/>
                            </Form.Group>
                        </Form>
                        <OverlayTrigger
                            placement="right"
                            overlay={<Popover>
                                <PopoverHeader>{isArtifact ? <>Undo Artifact</> : <>Make Artifact</>}</PopoverHeader>
                                <PopoverBody>
                                    {isArtifact ? <>Read aloud with screen reader</> : <>Not read aloud with screen reader</>}
                                </PopoverBody>
                            </Popover>
                            }>
                            <Form>
                                <Form.Check type="checkbox" label={<>is decorative</>} onChange={(e) => {
                                    if (e.target.checked) {
                                        if (figures.current[figureI.current].connectedElements.length > 0) {
                                            figures.current[figureI.current].connectedElements.map(figure => {
                                                figure.isArtifact = true;
                                                changeTagType({
                                                    structTree: figures.current,
                                                    selectedStructElem: figures.current[figureI.current],
                                                    modifications: modifications.current,
                                                    newType: "Artifact"
                                                });
                                            })
                                        }
                                        figures.current[figureI.current].isArtifact = true;
                                        changeTagType({
                                            structTree: figures.current,
                                            selectedStructElem: figures.current[figureI.current],
                                            modifications: modifications.current,
                                            newType: "Artifact"
                                        });
                                        setIsArtifact(true);
                                    } else {
                                        figures.current[figureI.current].isArtifact = false;
                                        changeTagType({
                                            structTree: figures.current,
                                            selectedStructElem: figures.current[figureI.current],
                                            modifications: modifications.current,
                                            newType: "Figure"
                                        });
                                        setIsArtifact(false);
                                    }
                                }} checked={isArtifact}>
                                </Form.Check>
                            </Form>
                        </OverlayTrigger>
                    </> : <h3>No figures found in the document</h3>}
            </div>
        }
    }

    return (
        <div className="workspaceArea"
             onMouseUp={() => startResizeValueMenu.current = null}
             onMouseMove={(e) => resizingX(e, startResizeValueMenu.current, setMenuSize)}
             style={startResizeValueMenu.current != null ? {userSelect: 'none'}: null}>
            <WarningNotComplete
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                titleMessage={"Have you checked the all figures?"}
                bodyMessage={<>It seems that you have not checked the following figures: {viewed.current.map((e, ei) => e ? null : ei + 1).filter(e => e != null).join(", ")} <br /> Do you want to check them before you continue?</>}
                currentTask={6}
                setTask={setStep}
                taskSelected={stepSelected}
            />
            <div id="menu" className="menu" style={{width: menuSize + "px"}}>
                <div className="resizeHandleX" onMouseDown={(e) => startResizeValueMenu.current = startResizeX(e)}></div>
                <Instructions
                    title="Step 6: Edit Alternative Text of Figures"
                    step={6}
                />
                {navigation({
                    callback: (i) => pageNum.current = changeElementI({
                        i: i,
                        elementI: figureI.current,
                        pageNum: pageNum.current,
                        elements: figures.current,
                        setElementI: (i) => {
                            figureI.current = i;
                            setRenderI(prevState => prevState + 1);
                        },
                        setElementPartI: (i) => {
                            figurePartI.current = i;
                            setRenderI(prevState => prevState + 1);
                        },
                        callback: changeFigureCallback
                    }),
                    length: figures.current.length,
                    i: figureI.current,
                    title: "Figures",
                    viewed: viewed.current
                })}
                {showFigure()}
                <StepNavigationButtons nextStep={() => setStepSelected(7)} previousStep={() => setStepSelected(5)}/>
            </div>
            <PageView
                pageNum={pageNum.current}
                canvasRef={canvasRef}
                zoomFactor={zoomFactor}
                setZoomFactor={setZoomFactor}
                isLoading={figuresQuery.isLoading || pageImage.isLoading}
                showPageMenu={showPageMenu}
                setShowPageMenu={setShowPageMenu}
                pdf={pdf}
                pdfInfo={pdfInfo}
                updateStep={updateStep}
            />
        </div>
    );
};

export default Figures;
