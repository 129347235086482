import React, {useEffect, useRef, useState} from 'react';
import LanguageButton from "./LanguageButton";
import Button from 'react-bootstrap/Button';
import { IoMdHelpCircleOutline, IoMdContact } from 'react-icons/io';
import {IoCloudDoneOutline, IoSchool} from 'react-icons/io5';
import {ImSection} from "react-icons/im";
import {AiOutlineDownload} from "react-icons/ai";
import {DownloadHandler} from "./utils/DownloadHandler";

const Header = ({language, changeLanguage, pdfInfo, pdf, setStep}) => {

    const [savedElapsed, setSavedElapsed] = useState(0);
    const [startDownload, setStartDownload] = useState(false);
    const saved = useRef(pdfInfo.saved);

    useEffect(() => {
        saved.current = pdfInfo.saved;
        return;
    }, [pdfInfo.saved]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (saved.current != null) {
                setSavedElapsed(Math.floor(((new Date()).getTime() - saved.current) / 1000));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="header">
            <DownloadHandler
                pdfInfo={pdfInfo}
                setStep={setStep}
                startDownload={startDownload}
                setStartDownload={setStartDownload}
                pdf={pdf}
            />
            <Button onClick={() => {
                localStorage.removeItem("pdf");
                localStorage.removeItem("task");
                window.location.href = '/';
            }} className="headerButton">
                <img src="img/logo.png" width="38" height="49" alt="Logo von PAVE"></img>
                <h1>PAVE 2.0</h1>
            </Button>
            {pdfInfo != null && pdfInfo.filename != null ? <div className="fileHeader">
                <div className="fileName">{pdfInfo.filename}</div>
                <Button className="headerButton" onClick={() => pdfInfo.updateStep(() => setStartDownload(true))}>
                    <AiOutlineDownload size="1.5em"/>
                </Button>
                <Button className="headerButton" onClick={() => pdfInfo.updateStep()}>
                    <IoCloudDoneOutline size="1.5em"/><br />
                    {savedElapsed < 60 ? (Math.floor(savedElapsed/10) * 10) + "s ago" : Math.floor(savedElapsed/60) + "min ago"}
                </Button>
            </div> : null}
            <div className="flex-fill">
            </div>
            <div className="linkButtons">
                <Button onClick={() => window.open('http://www.zhaw.ch/en', '_blank')}
                        className="headerButton"><IoSchool size={20}/>ZHAW</Button>
                <Button onClick={() => window.open('/contact')} className="headerButton"><IoMdContact size={20}/>Contact</Button>
                <Button onClick={() => window.open('/help')} className="headerButton"><IoMdHelpCircleOutline size={20}/>Help/FAQ</Button>
                <Button onClick={() => window.open('/legal')} className="headerButton"><ImSection size={20}/>Legal
                    Information</Button>
                <LanguageButton language={language} changeLanguage={changeLanguage}/>
            </div>
        </div>
    );
};

export default Header;
